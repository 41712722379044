














import Vue from "vue";
export default Vue.extend({
	mounted() {
		addEventListener("keydown", e => {
			if (e.keyCode == 27) this.$emit("isUpdated", false);
		});
	},
	computed: {
		getContent() {
			return this.$store.state.noticeContent;
		}
	}
});
