














































































































































import Vue from "vue";
import SwitchButton from "./components/SwitchButton.vue";
import Offline from "./components/Offline.vue";
import NoticeBar from "./components/NoticeBar.vue";
import LoadingBar from "./components/LoadingBar.vue";
import TopLoadingBar from "./components/TopLoadingBar.vue";
export default Vue.extend({
  name: "App",
  components: {
    SwitchButton,
    Offline,
    NoticeBar,
    LoadingBar,
    TopLoadingBar,
  },
  data() {
    return {
      isOffline: false,
      showMenu: false,
      showProfile: false,
      showAlarm: false,
      deferredPrompt: null as any,
      routerAnimation: "",

      isMounteRequired: false,
      idx: 0,
      barPositionX: "",

      isAlarmLoading: false,
    };
  },
  created() {
    let darkTheme = localStorage.getItem("circles.darkTheme");
    this.$store.state.darkTheme = darkTheme == "true";
    this.loginCheck();
  },
  mounted() {
    let startX = 0;
    let endX = 0;
    addEventListener("touchstart", (e: TouchEvent) => {
      startX = e.touches[0].clientX;
      endX = e.touches[0].clientX;
    });
    addEventListener("touchmove", (e: TouchEvent) => {
      endX = e.touches[0].clientX;
    });
    addEventListener("touchend", (e: TouchEvent) => {
      let gap = endX - startX;
      if (gap < -200) {
        this.showMenu = false;
      }
      if (gap > 200) {
        this.showMenu = true;
      }
    });
    addEventListener("offline", () => {
      this.isOffline = true;
    });
    addEventListener("online", () => {
      this.isOffline = false;
    });

    this.setBarPosition();

    window.addEventListener("beforeinstallprompt", (e: any) => {
      e.preventDefault();
      this.deferredPrompt = e;
      e.prompt();
    });

    let routeList = [this.$route.name] as any[];
    this.$router.beforeEach((to, from, next) => {
      this.$store.state.loadingStack = [];
      if (routeList.length > 1 && to.name == routeList[routeList.length - 2]) {
        this.routerAnimation = "routerdown-animation";
        routeList.splice(routeList.length - 1, 1);
        setTimeout(function () {
          next();
        }, 15);
        return;
      } else {
        this.routerAnimation = "routerup-animation";
        routeList.push(to.name);
        next();
      }
    });
  },

  watch: {
    $route(next, prev) {
      this.idx = ["home", "page", "community"].indexOf(next.name.split("/")[0]);
      if (this.idx != -1) {
        if (this.$refs.bar as HTMLDivElement) {
          this.setBarPosition();
        }
      }
    },
    "$store.state.darkTheme"(next, prev) {
      localStorage.setItem("circles.darkTheme", next);
    },
  },
  methods: {
    closeNotice() {
      this.$store.commit("closeNotice");
    },
    setBarPosition() {
      if (this.isShowMenuRoute) this.barPositionX = 120 * this.idx + "px";
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    toggleProfile() {
      this.showAlarm = false;
      this.showProfile = !this.showProfile;
    },
    toggleAlarm() {
      this.showProfile = false;
      this.showAlarm = !this.showAlarm;
      if (this.showAlarm) {
        this.isAlarmLoading = true;
        this.$store.dispatch("GET_ALARM").then(alarms => {
          this.getUserInformation.alarms = alarms;
          this.isAlarmLoading = false;
        });
      }
    },
    closeAll() {
      this.showMenu = false;
      this.showProfile = false;
      this.showAlarm = false;
    },
    getDate(date: Date): string {
      return new Date(date).toISOString().slice(0, 10);
    },
    deleteAlarm(id: number) {
      this.isAlarmLoading = true;
      this.$store.dispatch("REMOVE_ALARM", { id }).then(alarms => {
        this.isAlarmLoading = false;
        this.getUserInformation.alarms = alarms;
      });
    },
    showPWA() {
      this.deferredPrompt.prompt();
    },
    loginCheck() {
      let token = localStorage.getItem("circles.loginToken");
      let pushSubscription = localStorage.getItem("circles.pushSubscription");
      if (token) {
        this.$store.commit("pushLoading", {
          name: "GET_USER_PROFILE",
          message: "로그인 중",
        });
        this.$store
          .dispatch("GET_USER_PROFILE", { token, pushSubscription })
          .then(user => {
            this.$store.state.userToken = token;
            this.$store.commit("clearLoading", "GET_USER_PROFILE");
          })
          .catch(err => {
            this.$store.state.userToken = "";
            this.$store.commit("clearLoading", "GET_USER_PROFILE");
          });
      }
    },
    logout() {
      localStorage.removeItem("circles.loginToken");
      this.showProfile = false;
      this.$store.state.userToken = "";
      this.$store.state.userInformation = {};
      this.$router.replace("/");
    },
  },
  computed: {
    isNoticeOn() {
      return this.$store.state.showNotice;
    },
    isLoading() {
      return this.$store.state.loadingStack.length > 0;
    },
    getBar() {
      return this.$refs.bar;
    },
    getUserInformation(): any {
      return this.$store.state.userInformation;
    },
    getUserImage() {
      if (this.$store.state.userInformation.imgPath) return this.$store.state.mainPath + this.$store.state.userInformation.imgPath;
      else return "https://pbs.twimg.com/profile_images/770139154898382848/ndFg-IDH_400x400.jpg";
    },
    getClub() {
      return this.$store.state.club;
    },
    isShowMenuRoute(): boolean {
      return ["login", "register", "passwordchange", "page404", "community/editor", "site"].indexOf(this.$route.name || "") == -1 && !(this.$route.name == "home" && !this.getUserInformation.name);
    },
    getRank() {
      if (this.$store.state.club.name) {
        try {
          return this.$store.state.club.ranks.find((x: any) => this.$store.state.club.members.find((x: any) => x.user == this.$store.state.userInformation._id).rank == x.id).name;
        } catch (e) {
          return "-";
        }
      } else {
        return "-";
      }
    },
    isAdmin(): number {
      let acceptPermissions = [32, 42];
      if (this.$store.state.club.ranks && this.$store.state.userInformation._id) {
        let user = this.$store.state.club.members.find((member: any) => {
          return member.user == this.$store.state.userInformation._id;
        });
        if (user) {
          let rank = this.$store.state.club.ranks.find((rank: any) => rank.id == user.rank);
          if (rank.isAdmin) {
            return 1000;
          } else {
            return this.$store.state.club.ranks.find((rank: any) => rank.id == user.rank).permission.find((permission: string) => acceptPermissions.indexOf(parseInt(permission)) != -1);
          }
        } else return 0;
      } else return 0;
    },
    communityPermissionRoute(): string {
      return this.isAdmin == 1000 ? "editclub" : this.isAdmin == 32 ? "application" : this.isAdmin == 42 ? "calendar" : "";
    },
  },
});
