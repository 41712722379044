



























































































import Vue from "vue";

import TopLoadingBar from "@/components/TopLoadingBar.vue";

export default Vue.extend({
	components: {
		TopLoadingBar
	},
	data() {
		return {
			name: "",
			school: "",
			introduction: "",
			image: {} as any,

			errorAlert: "",

			schoolTimer: 0,
			schoolList: [] as { name: string }[],
			schoolCurrentIndex: 0,
			isShowSearchSchool: false,
			isSchoolLoading: false
		};
	},
	mounted() {
		(this.$refs.startInput as HTMLDivElement).focus();
		addEventListener("keydown", e => {
			if (e.keyCode == 27) this.$emit("isUpdated", false);
		});
	},
	methods: {
		encodeBase64ImageFile(image: File): Promise<string> {
			return new Promise<string>((resolve, reject) => {
				try {
					if (!image) return resolve("");
					let reader = new FileReader();
					reader.readAsDataURL(image);
					reader.onload = (event: any) => {
						resolve(event.target.result);
					};
					reader.onerror = error => {
						reject(error);
					};
				} catch (e) {
					resolve("");
				}
			});
		},
		onChangeFile(e: any) {
			this.image = e.target.files[0];
		},
		create() {
			this.$store.commit("pushLoading", {
				name: "CLUB_CREATE",
				message: "동아리 생성 중"
			});
			this.encodeBase64ImageFile(this.image).then(img => {
				this.$store
					.dispatch("CLUB_CREATE", {
						name: this.name,
						school: this.school,
						introduction: this.introduction,
						img: img ? img : undefined
					})
					.then(club => {
						this.$store.commit("clearLoading", "CLUB_CREATE");
						this.$emit("isUpdated", false);
					})
					.catch(err => {
						this.$store.commit("clearLoading", "CLUB_CREATE");
						this.errorAlert = err.response.data.message;
					});
			});
		},
		schoolKeyPress(e: any) {
			if (this.schoolCurrentIndex >= this.schoolList.length)
				this.schoolCurrentIndex = this.schoolList.length;
			switch (e.keyCode) {
				case 13:
					if (this.isShowSearchSchool && this.schoolList.length) {
						this.selectSchool(
							this.schoolList[this.schoolCurrentIndex].name
						);
					}
					break;
				case 38:
					if (this.schoolCurrentIndex > 0) this.schoolCurrentIndex--;
					break;
				case 40:
					if (this.schoolCurrentIndex < this.schoolList.length - 1)
						this.schoolCurrentIndex++;
					break;
			}
		},
		searchSchool() {
			this.isSchoolLoading = true;
			this.$store
				.dispatch("SEARCH_SCHOOL", this.school)
				.then(data => {
					this.isSchoolLoading = false;
					this.schoolList = data;
				})
				.catch(err => {
					this.isSchoolLoading = false;
					this.schoolList = [];
				});
		},
		showSchoolSearch() {
			this.isShowSearchSchool = true;
		},
		hideSchoolSearch() {
			setTimeout(() => {
				this.isShowSearchSchool = false;
			}, 100);
		},
		selectSchool(schoolName: string) {
			this.isShowSearchSchool = false;
			this.school = schoolName;
			this.schoolCurrentIndex = 0;
		}
	},
	watch: {
		school() {
			if (this.school == "") {
				this.schoolList = [];
				this.isShowSearchSchool = true;
			}
			clearTimeout(this.schoolTimer);
			this.schoolTimer = setTimeout(() => {
				this.searchSchool();
			}, 500);
		}
	},
	computed: {
		getImages() {
			return;
		}
	}
});
