







import Vue from "vue";
export default Vue.extend({
	model: {
		prop: "checked",
		event: "change"
	},
	props: {
		checked: Boolean
	}
});
