
























































































































































import Vue from "vue";
import CirclesCreatePopup from "../components/CirclesCreatePopup.vue";
export default Vue.extend({
	name: "Home",
	components: {
		CirclesCreatePopup
	},
	data() {
		return {
			searchClub: "",
			isShowCirclesCreatePopup: false,
			targetCurrentIndex: 0
		};
	},
	created() {
		if (this.$store.state.userToken) this.reload();
	},
	methods: {
		getImgPath(imgPath: string) {
			if (imgPath) return this.$store.state.mainPath + imgPath;
			else return "https://pbs.twimg.com/profile_images/770139154898382848/ndFg-IDH_400x400.jpg";
		},
		reload() {
			this.isShowCirclesCreatePopup = false;
			this.$store.commit("pushLoading", {
				name: "GET_USER_PROFILE",
				message: "동아리 다시 가져오는 중"
			});
			this.$store
				.dispatch("GET_USER_PROFILE", {
					token: this.$store.state.userToken,
					pushSubscription: localStorage.getItem("circles.pushSubscription")
				})
				.then(user => {
					this.$store.commit("clearLoading", "GET_USER_PROFILE");
				})
				.catch(err => {});
		},
		userInputKeyPress(e: any) {
			if (this.targetCurrentIndex >= this.getClubs.length) this.targetCurrentIndex = this.getClubs.length;
			switch (e.keyCode) {
				case 13:
					this.selectClub(this.targetCurrentIndex);
					break;
				case 38:
					if (this.targetCurrentIndex > 0) this.targetCurrentIndex--;
					break;
				case 40:
					if (this.targetCurrentIndex < this.getClubs.length - 1) this.targetCurrentIndex++;
					break;
			}
		},
		selectClub(idx: number) {
			let club = this.getClubs[idx];
			this.targetCurrentIndex = idx;
			if (club) {
				this.$store.commit("pushLoading", {
					name: "GET_CLUB",
					message: "동아리 가져오는 중"
				});
				this.$store
					.dispatch("GET_CLUB", club.name)
					.then(club => {
						this.$store.commit("clearLoading", "GET_CLUB");
						if (!club) this.$router.push("/");
						else this.$router.push(`/${club.name}/page/timeline`);
					})
					.catch(err => {
						this.$store.commit("clearLoading", "GET_CLUB");
						this.$router.push("/");
					});
			}
		},
		logout() {
			localStorage.removeItem("circles.loginToken");
			this.$store.state.userToken = "";
			this.$store.state.userInformation = {};
			this.$router.replace("/");
		}
	},
	computed: {
		getClub() {
			return this.$store.state.club;
		},
		getUserInformation() {
			return this.$store.state.userInformation;
		},
		getRank() {
			if (this.$store.state.club.name) {
				try {
					return this.$store.state.club.ranks.find((x: any) => this.$store.state.club.members.find((x: any) => x.user == this.$store.state.userInformation._id).rank == x.id).name;
				} catch (e) {
					return "-";
				}
			} else {
				return "-";
			}
		},
		getUserImage() {
			if (this.$store.state.userInformation.imgPath) return this.$store.state.mainPath + this.$store.state.userInformation.imgPath;
			else return "https://pbs.twimg.com/profile_images/770139154898382848/ndFg-IDH_400x400.jpg";
		},
		getClubs(): any[] {
			return this.getUserInformation.clubs.filter((club: any) => club.name.indexOf(this.searchClub) != -1);
		}
	}
});
